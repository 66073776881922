interface Breakpoints {
  tablet: number;
  tabletXL: number;
  desktop: number;
  desktopXL: number;
}

interface Spacers {
  mobile: number;
  tablet: number;
  tabletXL: number;
  desktop: number;
  desktopXL: number;

  container: {
    mobile: {
      top: number;
      bottom: number;
    };
    tablet: {
      top: number;
      bottom: number;
    };
    desktop: {
      top: number;
      bottom: number;
    };
  };
}

interface Sizes {
  maxContentWidth: number;
  headerAndFooter: {
    logo: number;
    logoContainer: number;
    headerAngled: number;
    headerCenter: number;
  };
}

interface Colors {
  green: string;
}

export const breakpoints: Breakpoints = {
  tablet: 800,
  tabletXL: 1024,
  desktop: 1280,
  desktopXL: 1920,
};

export const spacers: Spacers = {
  //normal side spacers
  mobile: 22,
  tablet: 37,
  tabletXL: 47,
  desktop: 60,
  desktopXL: 86,
  container: {
    mobile: {
      top: 20,
      bottom: 40,
    },
    tablet: {
      top: 30,
      bottom: 60,
    },
    desktop: {
      top: 45,
      bottom: 75,
    },
  },
};

export const sizes: Sizes = {
  maxContentWidth: 1160,
  headerAndFooter: {
    logo: 24,
    logoContainer: 25,
    headerAngled: 12.5,
    headerCenter: 25,
  },
};

export const colors: Colors = {
  green: "#90BE43",
};

export const isBrowser = typeof window !== "undefined";
