import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage } from "@react-three/drei";
import Model from "./Models/custom/Adapter";
//use this: https://gltf.pmnd.rs/

const Model3DLightbox = ({ url }) => {
  const [width, setWidth] = useState("99%");

  useEffect(() => {
    setTimeout(() => {
      setWidth("100%");
    }, 1000);
  }, []);

  const ref = useRef();

  return (
    <div style={{ cursor: "pointer" }}>
      <Canvas
        shadows
        dpr={[1, 2]}
        camera={{ fov: 50 }}
        style={{ height: "60vw", width: width }}
      >
        <ambientLight intensity={0.2} />
        <Suspense fallback={null}>
          <Stage controls={ref} environment={null}>
            <Model url={url} />
          </Stage>
        </Suspense>
        <OrbitControls enableZoom={false} ref={ref} autoRotate />
      </Canvas>
    </div>
  );
};

export default Model3DLightbox;
