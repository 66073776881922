import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import LangSwitch from "./LangSwitch";
import NavToggle from "./NavToggle";

interface NavControlProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  siteId: number;
  links?: [
    {
      uri: string;
      siteId: number;
    }
  ];
}

const NavControlDiv = styled.div`
  width: 25vw;
  height: 12.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(0.52 * 100vw * 0.24);
  z-index: 120;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: fixed;
    top: 4.2vw;
    right: 4.5vw;

    .toggleDiv {
      margin-left: 16px;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: block;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    max-width: 480px;
    max-height: 240px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      //top: 0;
      top: 60px;
      //right: calc((107.5vw - 1280px) / 2);

      .toggleDiv {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
`;

const NavControl: React.FC<NavControlProps> = ({
  navIsOpen,
  setNavIsOpen,
  siteId,
  //links,
}) => (
  <NavControlDiv>
    <div>
      {/* <LangSwitch siteId={siteId} links={links} /> */}
      <div className="toggleDiv">
        <NavToggle navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
      </div>
    </div>
  </NavControlDiv>
);

export default NavControl;
