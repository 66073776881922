import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ url }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(url);
  return (
    <group ref={group} dispose={null}>
      <group
        position={[-100.63, 3.2, -1.88]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1001"].geometry}
          material={materials.feuerverzinkt}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1001_1"].geometry}
          material={materials.Schrauben_etc}
        />
      </group>
    </group>
  );
}

// useGLTF.preload("/1.gltf");
